import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '@env/environment';

@Component({
    selector: 'app-game-list-entry',
    templateUrl: './game-list-entry.component.html',
    styleUrls: ['./game-list-entry.component.scss']
})
export class GameListEntryComponent implements OnInit {

    _game;
    @Input()
    set game(game) {
        this._game = game;
        if (game.options.teams !== 'Singles') {
            this._game.teams = this._game.teams.map(team => {
                team.players = team.players.map(playerId => game.players.find(player => player.id === playerId))
                return team;
            });
        }
    }
    @Input() opened;
    @Output() viewGame = new EventEmitter();
    @Output() joinGame = new EventEmitter();
    @Output() showTeamSelection = new EventEmitter();
    @Output() leaveGame = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    public getMapImageUrl(map) {
        return `${environment.mapsUrl}/${map.key}/${map.version}/${map.key}.jpg`;
    }

    public getGameLink(game) {
        return `${environment.websiteUrl}/beta/${game.id}`;
    }

    public getTurnLengthRepresentation(length: number) {
        if (length === 86400) {
            return '24 hours';
        } else {
            return (length / 60) + ' minutes';
        }
    }

    public getButtonAction(game) {
        // join, leave, view, victory, defeated, turn, team turn, view, ended, cancelled
        if (game.state === 'cancelled') {
            return 'cancelled';
        }
        switch (game.playerState) {
            case 'waiting':
                return 'leave';
            case 'active':
                return 'view';
            case 'not_player':
                switch (game.state) {
                    case 'waiting':
                        return 'join';
                    case 'running':
                    case 'paused':
                        return 'view';
                    default:
                        return game.state;
                }
            default:
                return game.playerState;
        }
    }
}

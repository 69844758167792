import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { GameService } from '@gamelist/services/api/game.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    selectedGameType = null;
    activeGames$;
    awaitingGames$;
    archivedGames$;
    selectedTab = 'active';

    constructor(private gameService: GameService) { }

    ngOnInit() {
        console.log('init dashboard');
        this.activeGames$ = this.gameService.getActivePlayerGames();
        this.awaitingGames$ = this.gameService.getAwaitingPlayerGames();
        this.archivedGames$ = this.gameService.getArchivedPlayerGames();
    }

    selectGameType(gameType: string) {
        this.selectedGameType = gameType;
    }

    startGame(type) {
        // TODO
        window.top.location.href = `${environment.websiteUrl}/beta/new?type=${type}`;
    }

    joinGame(type) {
        // TODO
        window.top.location.href = `${environment.websiteUrl}/beta/search?type=${type}&available_slots=1`;
    }

    searchGame() {
        window.top.location.href = `${environment.websiteUrl}/beta/search?showJoinedGames=true`;
    }

    selectTab(tab: string) {
        this.selectedTab = tab;
    }
}

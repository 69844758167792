import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { MapDataRegion, MapInfo, MapRegion, UIMap } from '@game/models/ui-map';
import { MapState } from '@game/models/map-state';
import { MapEventsService } from '@game/services/map-events.service';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit, OnChanges, OnDestroy {

    // initialization control
    private mapInfoLoaded = false;
    private regionsCanvasObjectsLoaded = false;
    private titlesCanvasObjectsLoaded = false;
    private containersCanvasObjectsLoaded = false;
    private uiMapLoaded = false;
    private mapLoaded = false;

    // canvas
    private canvasTitle: any;
    private canvasHighlight: any;
    private canvasContainer: any;
    private canvas: any;

    // canvas objects overlays
    private blackOverlays = {};
    private redOverlays = {};
    private whiteOverlays = {};
    private titleOverlays = {};
    private containerOverlays = {};
    private containersInfo = {};

    // stream of ui input events
    private uiInputStream$: Observable<any>;

    private containersPositionPerRegion = {};

    // canvas width and height
    private width: number;
    private height: number;

    private mapState: MapState;
    private uiMap: UIMap;

    private primaryHighlightedRegions = new Set();
    private secondaryHighlightedRegions = new Set();

    private selectedRegion1: MapRegion;
    private selectedRegion2: MapRegion;

    private onDestroy$ = new Subject<boolean>();

    /**
     * Template Variables
     */
    mapUrl: string;

    // TODO:
    // maybe we could extract the map out of this component, and that way we could display the map when map information is available
    // but we would only display this component when all the inputs become available, this way we wouldn't need to worry about the loading
    // order

    /**
     * Loading dependency:
     * mapInfo -> initializeCanvas() -> registerCanvasEvents()
     *                + regionCanvas -> add regions blocks to canvas -> render canvas
     *                + titlesCanvas -> add titles blocks to canvas  -> render title canvas
     */


    /**
     * Inputs
     */
    @Input()
    set mapInfo(mapInfo: MapInfo) {

        // since in the template we are using an ngif based on mapInfo, first time this is called mapInfo already as a value. This is
        // important! Otherwise the loading order can have problems
console.log('mapInfo', mapInfo);
        if (mapInfo !== null) {
            // set map image
            this.mapUrl = mapInfo.assets.map;
            this.width = mapInfo.data.map.w;
            this.height = mapInfo.data.map.h;

            // update containers info index
            this.updateContainersInfoIndex(mapInfo.data.containers);
            // update containers position index
            this.updateContainersPositionsIndex(mapInfo.data.regions);

            // initialize canvas objects
            this.initializeCanvas();

            // register canvas events
            this.registerCanvasEvents(this.canvas);

            this.mapInfoLoaded = true;
        }
    }

    @Input()
    set regionsCanvas(regions: any) { // TODO: typing

        if (regions !== null) {
            this.blackOverlays = regions.black;
            this.whiteOverlays = regions.white;
            this.redOverlays = regions.red;

            // add black regions to canvas
            Object.values(this.blackOverlays).forEach(regionObj => {
                this.canvas.add(regionObj);
            });

            this.regionsCanvasObjectsLoaded = true;

            // render canvas
            setTimeout(() => {
                // deffer to next cycle
                this.canvas.renderAll();
            }, 0);
        }
    }

    @Input()
    set titlesCanvas(titles: any) { // TODO: typing

        if (titles !== null) {
            this.titleOverlays = titles;

            // add titles to canvas
            Object.values(this.titleOverlays).forEach(titleObj => {
                this.canvasTitle.add(titleObj);
            });

            this.titlesCanvasObjectsLoaded = true;

            // render canvas
            this.canvasTitle.renderAll();
        }
    }

    @Input()
    set containersCanvas(containers: any) {

        if (containers !== null) {
            this.containerOverlays = containers;

            this.containersCanvasObjectsLoaded = true;
        }
    }

    @Input()
    set mapRepresentation(uiMap: UIMap) {

        if (uiMap !== null) {
            this.uiMap = uiMap;

            this.uiMapLoaded = true;

            if (this.mapLoaded) {
                setTimeout(() => {
                    // deffer to next cycle
                    this.updateMap();
                }, 0);
            }
        }
    }

    @Input()
    set state(state: MapState) {
        this.mapState = state;
    }

    @Input() playerId: number;
    @Input() showRegionNames: boolean;

    @Input()
    set fromRegion(region: MapRegion) {

        if (region) {
            // add region to the primary highlights
            this.primaryHighlightedRegions.add(region.id);
        } else if (this.selectedRegion1) {
            // remove previous region from the highlights
            this.primaryHighlightedRegions.delete(this.selectedRegion1.id);
        }

        // this.secondaryHighlightedRegions.clear();

        this.selectedRegion1 = region;

        this.updateHighlights(this.primaryHighlightedRegions, this.secondaryHighlightedRegions);
    }
    @Input()
    set toRegion(region: MapRegion) {

        if (region) {
            // add region to the primary highlights
            this.primaryHighlightedRegions.add(region.id);
        } else if (this.selectedRegion2) {
            // remove previous region from the highlights
            this.primaryHighlightedRegions.delete(this.selectedRegion2.id);
        }

        this.secondaryHighlightedRegions.clear();

        this.selectedRegion2 = region;

        this.updateHighlights(this.primaryHighlightedRegions, this.secondaryHighlightedRegions);
    }


    /**
     * Output
     */
    @Output() fromRegionSelected = new EventEmitter<string>();
    @Output() toRegionSelected = new EventEmitter<string>();


    constructor(private mapEventsService: MapEventsService) { }


    ngOnInit() {
        // create listeners that will handle canvas events like click and hover
        // we can do this at this step because it will just setup observables based on an observable
        this.createListeners();
    }

    ngOnChanges(changes: SimpleChanges): void {

        console.log('map-com-ngonchange line 246');

        if (this.mapInfoLoaded && this.containersCanvasObjectsLoaded && this.uiMapLoaded && !this.mapLoaded) {
            // we can now start the things
            
            setTimeout(() => {
                // deffer to next cycle
                this.updateMap();
            }, 0);

            this.mapLoaded = true;

            this.mapEventsService.highlight$
                .pipe(
                    takeUntil(this.onDestroy$)
                )
                .subscribe(regions => {
                    const mergedRegions = new Set([...Array.from(this.secondaryHighlightedRegions), ...regions]);

                    this.updateHighlights(
                        this.primaryHighlightedRegions,
                        mergedRegions
                    );
                })
            ;
        }
    }

    /**
     * Initialize canvas
     */
    private initializeCanvas() {
        this.canvasTitle = new fabric.StaticCanvas('canvasTitle', {
            stateful: false,
            renderOnAddRemove: false,
            width: this.width,
            height: this.height,
            hoverCursor: 'default'
        });

        this.canvasHighlight = new fabric.StaticCanvas('canvasHighlight', {
            stateful: false,
            renderOnAddRemove: false,
            width: this.width,
            height: this.height,
            hoverCursor: 'default'
        });

        this.canvasContainer = new fabric.StaticCanvas('canvasContainer', {
            stateful: false,
            renderOnAddRemove: false,
            width: this.width,
            height: this.height,
            hoverCursor: 'default'
        });

        this.canvas = new fabric.Canvas('canvas', {
            isDrawingMode: false, // by default fabric js using draw mode, so we disable it (its not drawing game)
            selection: false, // by default fabric js can select object by dragging, this is not behaviour we looking for
            stateful: false,
            renderOnAddRemove: false,
            width: this.width,
            height: this.height,
            hoverCursor: 'default'
        });
    }

    /**
     * Register canvas events
     * mouseover, moutout and mouseup events are published to the uiInputStream
     */
    private registerCanvasEvents(canvas: any) {

        const events = [
            'mouse:over',
            'mouse:out',
            'mouse:up'
        ];
        
        // build an event stream with the defined event types
        const eventStreams = events.map(ev => fromEvent(canvas, ev)
            .pipe(
                filter((event: any) => event && event.target !== null),
                map((event: any) => {
                    return {
                        type: ev,
                        object: event.target,
                        region: event.target.name
                    };
                })
            )
        );
        console.log('eventStreams', eventStreams);
        // tslint:disable-next-line
        this.uiInputStream$ = merge(...eventStreams);
    }

    /**
     * Create listeners based on the uiInputStream
     */
    private createListeners() {

        // mouse over
        this.uiInputStream$
            .pipe(
                filter(event => event.type === 'mouse:over'),
                takeUntil(this.onDestroy$)
            )
            .subscribe((event) => {

                const tmpPrimaryHighlights = [];
                let tmpSecondaryHighlights = [];

                // by default set cursor to normal
                event.object.hoverCursor = 'default';

                if (this.mapState === MapState.Normal) {
                    // case 1 (idle)

                    // primary highlight hovered region
                    tmpPrimaryHighlights.push(event.region);

                    // secondary highlight adjacent regions
                    tmpSecondaryHighlights = this.uiMap.getAdjacentRegions(event.region);

                } else if (this.mapState === MapState.Deploy) {
                    // case 2 (deploy)

                    // primary highlight hovered region
                    tmpPrimaryHighlights.push(event.region);

                    // secondary highlight adjacent regions
                    tmpSecondaryHighlights = this.uiMap.getAdjacentRegions(event.region);

                    // set cursor to pointer if region is deployable
                    if (this.uiMap.canDeploy(this.playerId, event.region)) {
                        event.object.hoverCursor = 'pointer';
                    }

                } else if (this.mapState === MapState.Assault && !this.selectedRegion1) {
                    // case 3 (assault no region selected)

                    // primary highlight hovered region
                    tmpPrimaryHighlights.push(event.region);

                    // secondary highlight attackable regions
                    if (this.uiMap.isOwner(event.region, this.playerId)) {
                        tmpSecondaryHighlights = this.uiMap.getAttackableRegions(this.playerId, event.region);
                    }

                    // set cursor to pointer if region is selectable
                    if (this.uiMap.canAttackFrom(this.playerId, event.region)) {
                        event.object.hoverCursor = 'pointer';
                    }

                } else if (this.mapState === MapState.Assault && this.selectedRegion1 && !this.selectedRegion2) {
                    // case 4 (assault and first region already selected

                    const canAttack = this.uiMap.canAttack(this.playerId, this.selectedRegion1.id, event.region);

                    if (canAttack) {
                        tmpPrimaryHighlights.push(event.region);
                    }

                    // set cursor to pointer if region is attackable
                    if (canAttack) {
                        event.object.hoverCursor = 'pointer';
                    }

                } else if (this.mapState === MapState.Reinforce && !this.selectedRegion1) {
                    // case 5 (reinforcement no region selected)

                    // primary highlight hovered region
                    tmpPrimaryHighlights.push(event.region);

                    // secondary highlight attackable from regions
                    if (this.uiMap.isOwner(event.region, this.playerId)) {
                        tmpSecondaryHighlights = this.uiMap.getReinforceableRegions(this.playerId, event.region);
                    }

                    // set cursor to pointer if region is selectable
                    if (this.uiMap.canReinforceFrom(this.playerId, event.region)) {
                        event.object.hoverCursor = 'pointer';
                    }

                } else if (this.mapState === MapState.Reinforce && this.selectedRegion1 && !this.selectedRegion2) {
                    // case 6 (reinforcement and first region already selected

                    const canReinforce = this.uiMap.canReinforce(this.playerId, this.selectedRegion1.id, event.region);

                    if (canReinforce) {
                        tmpPrimaryHighlights.push(event.region);
                    }

                    // set cursor to pointer if region is reinforceable
                    if (canReinforce) {
                        event.object.hoverCursor = 'pointer';
                    }

                }

                // update highlight canvas
                this.updateHighlights(
                    new Set([...Array.from(this.primaryHighlightedRegions), ...tmpPrimaryHighlights]),
                    new Set([...Array.from(this.secondaryHighlightedRegions), ...tmpSecondaryHighlights])
                );
            })
        ;

        // mouse out
        this.uiInputStream$
            .pipe(
                filter(event => event.type === 'mouse:out'),
                takeUntil(this.onDestroy$)
            )
            .subscribe(event => {

                // change cursor to default
                event.object.hoverCursor = 'default';

                /*
                if (this.mapState === MapState.Normal || this.mapState === MapState.Deploy) {
                    this.primaryHighlightedRegions = [];
                    this.secondaryHighlightedRegions = [];

                    if (this.selectedRegion1) {
                        this.primaryHighlightedRegions.push(this.selectedRegion1.id);
                    }

                } else if (this.mapState === MapState.Assault) {
                    if (this.selectedRegion1) {
                        this.primaryHighlightedRegions = [this.selectedRegion1.id];
                        this.secondaryHighlightedRegions = this.uiMap.getAttackableRegions(this.playerId, this.selectedRegion1.id);
                    } else if (this.selectedRegion2) {
                        this.primaryHighlightedRegions = [this.selectedRegion1.id, this.selectedRegion2.id];
                        this.secondaryHighlightedRegions = this.uiMap.getAttackableRegions(this.playerId, this.selectedRegion1.id);
                    } else {
                        this.primaryHighlightedRegions = [];
                        this.secondaryHighlightedRegions = [];
                    }
                } else if (this.mapState === MapState.Reinforce) {
                    if (this.selectedRegion1) {
                        this.primaryHighlightedRegions = [this.selectedRegion1.id];
                        this.secondaryHighlightedRegions = this.uiMap.getReinforceableRegions(this.playerId, this.selectedRegion1.id);
                    } else if (this.selectedRegion2) {
                        this.primaryHighlightedRegions = [this.selectedRegion1.id, this.selectedRegion2.id];
                        this.secondaryHighlightedRegions = this.uiMap.getReinforceableRegions(this.playerId, this.selectedRegion1.id);
                    } else {
                        this.primaryHighlightedRegions = [];
                        this.secondaryHighlightedRegions = [];
                    }
                }
                */

                // update highlight canvas with fixed only
                this.updateHighlights(this.primaryHighlightedRegions, this.secondaryHighlightedRegions);
            })
        ;

        // region click
        this.uiInputStream$
            .pipe(
                filter(event => event.type === 'mouse:up'),
                takeUntil(this.onDestroy$)
            )
            .subscribe(event => {

                if (this.mapState === MapState.Deploy && this.uiMap.canDeploy(this.playerId, event.region)) {

                    // select region
                    this.fromRegionSelected.emit(event.region);

                } else if (this.mapState === MapState.Assault && !this.selectedRegion1 && this.uiMap.canAttackFrom(this.playerId, event.region)) {

                    // select region
                    this.fromRegionSelected.emit(event.region);

                    // secondary highlight attackable regions
                    this.secondaryHighlightedRegions = new Set(this.uiMap.getAttackableRegions(this.playerId, event.region)
                        // do not highlight the hovered region
                            .filter(r => r !== event.region)
                    );

                } else if (this.mapState === MapState.Assault && this.selectedRegion1 && !this.selectedRegion2) {

                    const canAttack = this.uiMap.canAttack(this.playerId, this.selectedRegion1.id, event.region);

                    if (this.selectedRegion1.id === event.region) {
                        // region was aleady selected, unselect it
                        this.fromRegionSelected.emit(null);
                        // clear secondary
                        this.secondaryHighlightedRegions.clear();
                    } else if (canAttack) {
                        // a different region was selected
                        this.toRegionSelected.emit(event.region);
                    }

                    this.updateHighlights(this.primaryHighlightedRegions, this.secondaryHighlightedRegions);

                } else if (this.mapState === MapState.Reinforce && !this.selectedRegion1 && this.uiMap.canReinforceFrom(this.playerId, event.region)) {

                    // select region
                    this.fromRegionSelected.emit(event.region);

                    // secondary highlight reinforceable regions
                    this.secondaryHighlightedRegions = new Set(this.uiMap.getReinforceableRegions(this.playerId, event.region)
                        // do not highlight the hovered region
                            .filter(r => r !== event.region)
                    );

                } else if (this.mapState === MapState.Reinforce && this.selectedRegion1 && !this.selectedRegion2) {

                    const canReinforce = this.uiMap.canReinforce(this.playerId, this.selectedRegion1.id, event.region);

                    if (this.selectedRegion1.id === event.region) {
                        // region was aleady selected, unselect it
                        this.fromRegionSelected.emit(null);
                        // clear secondary
                        this.secondaryHighlightedRegions.clear();
                    } else if (canReinforce) {
                        // a different region was selected
                        this.toRegionSelected.emit(event.region);
                    }

                    this.updateHighlights(this.primaryHighlightedRegions, this.secondaryHighlightedRegions);
                }
            })
        ;
    }

    /**
     * Update map from UIMap
     * This will update the troops on regions
     */
    private updateMap() {

        // TODO: improve this not to modify containers of regions that didn't had any update

        this.canvasContainer.clear();

        for (const [regionId, regionInfo] of Object.entries(this.uiMap.regions)) {
            // iterate over all regions

            if (regionInfo.container) {

                // should we load the 2 digit or 3 digit container?
                const type = regionInfo.troops > 99 ? '3digit' : '2digit';

                // get the container to be used
                const container = this.containerOverlays[regionInfo.container][type];

                // get container information about canvas object to create
                const containerInfo = this.containersInfo[regionInfo.container];

                // create text overlay
                const text = new fabric.Text(regionInfo.troops.toString(), {
                    left: this.containersPositionPerRegion[regionId].x, // container.left,
                    top: this.containersPositionPerRegion[regionId].y, // container.top,
                    scaleX: 1, // TODO?
                    scaleY: 1,
                    fill: containerInfo.textColor,
                    fontSize: containerInfo[type].fontSize,
                    fontFamily: containerInfo[type].font,
                    textAlign: containerInfo[type].alignment,
                    originX: 'center',
                    originY: 'center',
                    name: regionId + '-container-text'
                });

                // clone container
                const newContainer = fabric.util.object.clone(container);
                newContainer.left = this.containersPositionPerRegion[regionId].x;
                newContainer.top = this.containersPositionPerRegion[regionId].y;
                this.canvasContainer.add(newContainer);

                this.canvasContainer.add(text);
            }
        }

        this.canvasContainer.renderAll();
    }




    /**
     * Auxiliary functions
     */

    /**
     * Creates an index table to get containers by their id
     */
    private updateContainersInfoIndex(containers) {
        this.containersInfo = containers.reduce((aux, container) => {
            aux[container.id] = container;
            return aux;
        }, {});
    }

    /**
     * Creates an index table to get containers positions by the region
     */
    private updateContainersPositionsIndex(regions: MapDataRegion[]) {
        this.containersPositionPerRegion = regions.reduce((aux, region) => {
            aux[region.id] = region.container;
            return aux;
        }, {});
    }

    /**
     * Auxiliary function to remove all canvas objects by type
     */
    private removeFromCanvas(canvas: any, type: string) {
        // filter objects by type
        const objects = canvas.getObjects().filter(obj => obj.type === type);
        // remove from canvas
        for (const obj of objects) {
            canvas.remove(obj);
        }
    }


    /**
     * Map Actions
     */


    private updateHighlights(primaryHighlightedRegions, secondaryHighlightedRegions) {

        // TODO: overlays may not be ready yet...

        // clear highlights
        this.canvasHighlight.clear();

        const primary = Array.from(primaryHighlightedRegions.values());
        // we don't want to include regions on secondary that are already on primary, so we only have one overlay per region
        const secondary = Array.from(secondaryHighlightedRegions.values()).filter(val => !primary.includes(val));

        for (const regionId of primary) {
            const redOverlay = this.redOverlays[regionId as string];

            if (redOverlay) {
                this.canvasHighlight.add(redOverlay);

                if (!this.showRegionNames) {
                    const title = this.titleOverlays[regionId as string];
                    this.canvasHighlight.add(title);
                }
            }
        }

        for (const regionId of secondary) {
            const whiteOverlay = this.whiteOverlays[regionId as string];

            if (whiteOverlay) {
                this.canvasHighlight.add(whiteOverlay);

                if (!this.showRegionNames) {
                    const title = this.titleOverlays[regionId as string];
                    this.canvasHighlight.add(title);
                }
            }
        }

        // render canvas highlights
        this.canvasHighlight.renderAll();
    }


    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();

        this.canvas.dispose();
        this.canvasTitle.dispose();
        this.canvasContainer.dispose();
        this.canvasHighlight.dispose();
    }
}

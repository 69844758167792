import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameListComponent } from '@gamelist/components/game-list/game-list.component';
import { NewGameComponent } from '@gamelist/pages/new-game/new-game.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { GameListEntryComponent } from './components/game-list-entry/game-list-entry.component';
import { GameSearchComponent } from './pages/game-search/game-search.component';
import { TeamSelectionComponent } from '@gamelist/components/team-selection/team-selection.component';
import { ModalComponent } from '@gamelist/components/modal/modal.component';
import { GameAdvancedSearchComponent } from './components/game-advanced-search/game-advanced-search.component';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ScoreboardComponent } from './pages/scoreboard/scoreboard.component';
import { TooltipModule } from 'ng2-tooltip-directive';

@NgModule({
    imports: [
        CommonModule,
        TagInputModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        TooltipModule
    ],
    exports: [
        GameListComponent
    ],
    declarations: [
        GameListComponent,
        NewGameComponent,
        DashboardComponent,
        GameListEntryComponent,
        GameSearchComponent,
        TeamSelectionComponent,
        ModalComponent,
        GameAdvancedSearchComponent,
        ScoreboardComponent
    ]
})
export class GameListModule { }

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GameService } from '@game/services/api/game.service';
import {
    AdvanceTroopsAction,
    AttackAction,
    BeginTurnAction,
    BlitzAction,
    CallUpReservesAction,
    ClearAttacksAction,
    DeployTroopsAction,
    EndAssaultAction,
    EndReinforcementAction,
    GetChatMessagesAction,
    GetCurrentGameAction,
    GetGamePreferencesAction,
    GetGameRecordAction,
    GetReinforceableRegionsAction,
    InitGameAction,
    JoinAction,
    JoinTeamAction,
    LeaveAction,
    ReinforceRegionAction,
    ReturnToBaseAction,
    SendChatMessageAction,
    SetFromRegionAction,
    SetPlayerColorAction,
    SetPlayerLastReadChatAction,
    SetToRegionAction,
    SkipDeploymentAction,
    SkipReserveCallUpAction
} from '@game/store/actions/actions';
import { GamePlayer, GameStateModel, GameStatus, PlayerStatus } from '@game/store/models/game.model';
import produce from 'immer';
import {
    AssaultEndedEvent,
    AssaultStartedEvent,
    ChatUpdatedEvent,
    DeferredTroopsDeploymentEndedEvent,
    DeferredTroopsDeploymentStartedEvent,
    DeploymentEndedEvent,
    DeploymentStartedEvent,
    GameCancelledEvent,
    GameEndedEvent,
    GamePausedEvent,
    GamePreferencesUpdatedEvent,
    GameRecordUpdatedEvent,
    GameResumedEvent,
    GameStartedEvent,
    GameUpdatedEvent,
    PlayerBecameActiveEvent,
    PlayerDefeatedEvent,
    PlayerGoneAWOLEvent,
    PlayerJoinedEvent,
    PlayerLeftEvent,
    PlayerReturnedToBaseEvent,
    RegionAdvancedEvent,
    RegionAttackedEvent,
    RegionAutoDeployedEvent,
    RegionBlitzedEvent,
    RegionBombardedEvent,
    RegionConqueredEvent,
    RegionReinforcedEvent,
    ReinforceableRegionsUpdatedEvent,
    ReinforcementEndedEvent,
    ReinforcementStartedEvent,
    ReserveAwardedEvent,
    ReserveCallUpEndedEvent,
    ReserveCallUpStartedEvent,
    ReserveSetCalledUpEvent,
    RoundEndedEvent,
    RoundStartedEvent,
    TroopsDeployedEvent,
    TurnBeganEvent,
    TurnEndedEvent,
    TurnStartedEvent, UpdateClockEvent
} from '@game/store/actions/events';


@State<GameStateModel>({
    name: 'game',
    defaults: {
        lastAction: 0,
        lastActionTime: 0,
        options: null,
        state: null,
        preferences: null,
        locked: false,
        messages: [],
        record: [],
        player: null,
        attacks: [],
        lastReadMessageTime: 0,
        serverTime: null
    } as GameStateModel
})
export class StoreGameState {

    constructor(private gameService: GameService) {
        console.log('we are in game.state');
    }


    /**
     * UI Actions
     */

    @Action(InitGameAction)
    initGame(ctx: StateContext<GameStateModel>, action: InitGameAction) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.player = action.player;
            }),
        );
    }

    @Action(GetCurrentGameAction)
    getCurrentGame() {
        this.gameService.getCurrentGame();
    }

    @Action(GetGamePreferencesAction)
    getGamePreferences() {
        this.gameService.getGamePrefereces();
    }

    @Action(GetGameRecordAction)
    getGameRecord() {
        this.gameService.getGameRecord();
    }

    @Action(JoinAction)
    join() {
        this.gameService.join();
    }

    @Action(JoinTeamAction)
    joinTeam(ctx, action: JoinTeamAction) {
        this.gameService.joinTeam(action.team);
    }

    @Action(LeaveAction)
    leave() {
        this.gameService.leave();
    }

    @Action(BeginTurnAction)
    beginTurn() {
        this.gameService.beginTurn();
    }

    @Action(DeployTroopsAction)
    deployTroops(ctx, action: DeployTroopsAction) {
        this.gameService.deployTroops(action.region, action.count);
    }

    @Action(SkipDeploymentAction)
    skipDeployment() {
        this.gameService.skipDeployment();
    }

    @Action(AttackAction)
    attack(ctx, action: AttackAction) {
        this.gameService.attack(action.fromRegion, action.toRegion);
    }

    @Action(BlitzAction)
    blitz(ctx, action: BlitzAction) {
        this.gameService.blitz(action.fromRegion, action.toRegion);
    }

    @Action(AdvanceTroopsAction)
    advanceTroops(ctx, action: AdvanceTroopsAction) {
        this.gameService.advanceTroops(action.count);
    }

    @Action(EndAssaultAction)
    endAssault() {
        this.gameService.endAssault();
    }

    @Action(ReinforceRegionAction)
    reinforceRegion(ctx, action: ReinforceRegionAction) {
        this.gameService.reinforceRegion(action.fromRegion, action.toRegion, action.troops);
    }

    @Action(EndReinforcementAction)
    endReinforcement() {
        this.gameService.endReinforcement();
    }

    @Action(ReturnToBaseAction)
    returnToBase() {
        this.gameService.returnToBase();
    }

    @Action(CallUpReservesAction)
    callUpReserves(ctx, action: CallUpReservesAction) {
        this.gameService.callUpReserves(action.reserves);
    }

    @Action(SkipReserveCallUpAction)
    skipReserveCallUp() {
        this.gameService.skipReserveCallUp();
    }

    /* others */

    @Action(GetChatMessagesAction)
    getChatMessages() {
        this.gameService.getChatMessages();
    }

    @Action(SendChatMessageAction)
    sendChatMessage(ctx, action: SendChatMessageAction) {
        this.gameService.sendChatMessage(action.message, action.scope);
    }

    @Action(SetPlayerColorAction)
    setPlayerColors(ctx, action: SetPlayerColorAction) {
        const playerColors = Object.assign({}, ctx.getState().preferences.playerColors);
        playerColors[action.player] = action.color;
        this.gameService.setPlayerColors(playerColors);
    }

    @Action(SetPlayerLastReadChatAction)
    setPlayerLastReadChat(ctx: StateContext<GameStateModel>, action: SetPlayerLastReadChatAction) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.lastReadMessageTime = action.timestamp;
            }),
        );
        this.gameService.setPlayerLastReadChat(action.timestamp);
    }

    @Action(BeginTurnAction)
    @Action(DeployTroopsAction)
    lockState(ctx: StateContext<GameStateModel>) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.locked = true;
            }),
        );
    }

    @Action(SetFromRegionAction)
    setFromRegion(ctx: StateContext<GameStateModel>, event: SetFromRegionAction) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update from region
                draft.state.fromRegion = event.region;
                // clean up attacks
                draft.attacks = [];
            })
        );
    }

    @Action(SetToRegionAction)
    setToRegion(ctx: StateContext<GameStateModel>, event: SetToRegionAction) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update to region
                draft.state.toRegion = event.region;
            })
        );
    }

    @Action(GetReinforceableRegionsAction)
    getReinforceableRegions() {
        this.gameService.getReinforceableRegions();
    }

    @Action(ClearAttacksAction)
    clearAttacks(ctx: StateContext<GameStateModel>) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update attacks
                draft.attacks = [];
            })
        );
    }


    /**
     * Events
     */

    @Action(GameUpdatedEvent)
    gameUpdated(ctx: StateContext<GameStateModel>, event: GameUpdatedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {

                // update all state
                draft.state   = event.payload.state;
                draft.state.reinforceableRegions = {};
                draft.options = event.payload.options;
                draft.lastAction = event.payload.lastAction;
                draft.lastActionTime = event.payload.lastActionTime;

                if (event.payload.state.state !== GameStatus.WaitingForPlayers) {
                    const regions = event.payload.state.regions;

                    draft.state.players = this.computePlayerRegions(draft.state.players, regions);
                } else {
                    // before all players have joined there are no regions associated with the players
                    draft.state.players = draft.state.players.map(player => {
                        player.regions = [];
                        return player;
                    });
                }
            })
        );
    }

    @Action(GamePreferencesUpdatedEvent)
    gamePreferencesUpdated(ctx: StateContext<GameStateModel>, event: GamePreferencesUpdatedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update preferences
                draft.preferences = {
                    playerColors: event.payload.playerColors
                };

                if (event.payload.lastReadChat) {
                    draft.lastReadMessageTime = event.payload.lastReadChat;
                }
            })
        );
    }

    @Action(GameRecordUpdatedEvent)
    gameRecordUpdated(ctx: StateContext<GameStateModel>, event: GameRecordUpdatedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update game record
                // TODO: maybe some of the side effects don't need to be pushed to record, check this
                draft.record.push(...event.payload);
            })
        );
    }

    /**
     * Side effects
     */

    // TODO: what to do with timestamp of all fxs?

    @Action(PlayerJoinedEvent)
    playerJoined(ctx: StateContext<GameStateModel>, event: PlayerJoinedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // before all players have joined there are no regions associated with the players
                const player = {...event.payload.player} as GamePlayer;
                player.regions = [];

                // add player to players list
                draft.state.players.push(player);

                // if player has a team update available team slots and players
                if (player.team) {
                    const team = StoreGameState.getTeam(draft, player.team);
                    team.availableSlots--;
                    team.players.push(player.id);
                }
            })
        );

        // get player preferences so colors are updated
        this.getGamePreferences();
    }

    @Action(PlayerLeftEvent)
    playerLeft(ctx: StateContext<GameStateModel>, event: PlayerLeftEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                const player = StoreGameState.getPlayer(draft, event.payload.player);
                if (player.team) {
                    // player was on a team, we need to update team status
                    const team = StoreGameState.getTeam(draft, player.team);
                    team.availableSlots++;
                    team.players = team.players.filter(playerId => playerId !== player.id);
                }
                // remove player from player list
                draft.state.players = draft.state.players.filter(gameplayer => gameplayer.id !== event.payload.player);
            })
        );
    }


    @Action(RoundStartedEvent)
    roundStarted(ctx: StateContext<GameStateModel>, event: RoundStartedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update round
                draft.state.round = event.payload.round;
                // TODO: what to do with troopsDue value? update only for current player or all players?
            })
        );
    }

    @Action(RoundEndedEvent)
    roundEnded(ctx: StateContext<GameStateModel>, event: RoundEndedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update round
                draft.state.round = event.payload.round;
                draft.state.state = GameStatus.WaitingToStartTurn;

                if (event.payload.deferredTroops) {
                    // update deferred troops due
                    const player = StoreGameState.getPlayer(draft, event.payload.round.turn.player);
                    player.deferredTroops = event.payload.deferredTroops.troops;
                }
            })
        );
    }


    @Action(TurnStartedEvent)
    turnStarted(ctx: StateContext<GameStateModel>, event: TurnStartedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update turn
                draft.state.round.turn = event.payload.turn;
                // TODO: what to do with troopsDue value? update only for current player or all players?
            })
        );
    }

    @Action(TurnBeganEvent)
    turnBegan(ctx: StateContext<GameStateModel>, event: TurnBeganEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                draft.state.round = event.payload.round;
            })
        );
    }

    @Action(TurnEndedEvent)
    turnEnded(ctx: StateContext<GameStateModel>, event: TurnEndedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                draft.state.round.turn = event.payload.turn;
                draft.state.state = GameStatus.WaitingToStartTurn;

                if (event.payload.deferredTroops) {
                    // update deferred troops due
                    const player = StoreGameState.getPlayer(draft, event.payload.turn.player);
                    player.deferredTroops = event.payload.deferredTroops.troops;
                }
            })
        );
    }


    @Action(GameStartedEvent)
    gameStarted(ctx: StateContext<GameStateModel>, event: GameStartedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                draft.state.regions = event.payload.regions;
                draft.state.players = this.computePlayerRegions(event.payload.players, event.payload.regions);
                draft.state.state = GameStatus.WaitingToStartTurn;
                draft.state.troopsOnCallUp = event.payload.troopsOnCallUp;

                if (event.payload.target) {
                    const player = StoreGameState.getPlayer(draft, draft.player);
                    player.target = event.payload.target;
                }
            })
        );
    }

    @Action(GamePausedEvent)
    gamePaused(ctx: StateContext<GameStateModel>, event: GamePausedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                draft.state.state = GameStatus.Paused;
            })
        );
    }

    @Action(GameResumedEvent)
    gameResumed(ctx: StateContext<GameStateModel>, event: GameResumedEvent) {
        // TODO: unblock UI operations
        // TODO: restart counter
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                draft.state.state = event.payload.state;
                if (event.payload.turnEndsIn) {
                    draft.state.round.turn.endsIn = event.payload.turnEndsIn;
                }
                draft.serverTime = event.payload.timestamp;
            })
        );
    }

    @Action(GameEndedEvent)
    gameEnded(ctx: StateContext<GameStateModel>, event: GameEndedEvent) {
        // TODO: show popup victory
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                draft.state.state = GameStatus.Ended;
                draft.state.winner = event.payload.winner;
                draft.state.timeTaken = event.payload.timeTaken;
                draft.state.turnsTaken = event.payload.turnsTaken;
                draft.state.pointsWon = event.payload.points;
            })
        );
    }

    @Action(GameCancelledEvent)
    gameCancelled(ctx: StateContext<GameStateModel>, event: GameCancelledEvent) {
        // TODO: show popup with the reason why the game was cancelled
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                draft.state.state = GameStatus.Cancelled;
                draft.state.reason = event.payload.reason;
                draft.state.turnsTaken = event.payload.turnsTaken;
                draft.state.timeTaken = event.payload.timeTaken;
            })
        );
    }


    @Action(DeploymentStartedEvent)
    deploymentStarted(ctx: StateContext<GameStateModel>, event: DeploymentStartedEvent) {
        // TODO: change map action to deployment if it's players turn
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update game state
                draft.state.state = GameStatus.Deployment;
                // update troops due
                const player = StoreGameState.getPlayer(draft, event.payload.player);
                player.troopsDue = event.payload.troopsDue;
            })
        );
    }

    @Action(TroopsDeployedEvent)
    troopsDeployed(ctx: StateContext<GameStateModel>, event: TroopsDeployedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                this.updateRegionTroops(draft, event.payload.region, event.payload.count, true);

                const player = StoreGameState.getPlayer(draft, event.payload.player);

                if (!event.payload.deferred) {
                    // update troops due
                    player.troopsDue -= event.payload.count;
                } else {
                    // update deferred troops
                    player.deferredTroops -= event.payload.count;
                }

                // reset region selection
                draft.state.fromRegion = null;
                draft.state.toRegion = null;
            })
        );
    }

    @Action(DeploymentEndedEvent)
    deploymentEnded(ctx: StateContext<GameStateModel>, event: DeploymentEndedEvent) {
        // TODO: change map action to idle
    }


    @Action(AssaultStartedEvent)
    assaultStarted(ctx: StateContext<GameStateModel>, event: AssaultStartedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                draft.state.state = GameStatus.Attack;
                draft.attacks = [];
            })
        );
    }

    @Action(RegionAttackedEvent)
    regionAttacked(ctx: StateContext<GameStateModel>, event: RegionAttackedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update number of troops in each region
                this.updateRegionTroops(draft, event.payload.fromRegion, event.payload.fromRegionTroops, false);
                this.updateRegionTroops(draft, event.payload.toRegion, event.payload.toRegionTroops, false);

                draft.attacks.push(
                    {
                        attackDice: event.payload.attackDice,
                        defendDice: event.payload.defendDice,
                        attackResult: event.payload.attackResult,
                        defendResult: event.payload.defendResult
                    }
                );
            })
        );
    }

    @Action(RegionBlitzedEvent)
    regionBlitzed(ctx: StateContext<GameStateModel>, event: RegionBlitzedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update number of troops in each region
                this.updateRegionTroops(draft, event.payload.fromRegion, event.payload.fromRegionTroops, false);
                this.updateRegionTroops(draft, event.payload.toRegion, event.payload.toRegionTroops, false);

                draft.attacks = event.payload.attacks;
            })
        );
    }

    @Action(RegionConqueredEvent)
    regionConquered(ctx: StateContext<GameStateModel>, event: RegionConqueredEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {

                // update region ownership and update troops to 0
                draft.state.regions[event.payload.region].player = event.payload.conqueredBy;
                draft.state.regions[event.payload.region].troops = 0;

                const attacker = StoreGameState.getPlayer(draft, event.payload.conqueredBy);
                const defender = StoreGameState.getPlayer(draft, event.payload.conqueredFrom);

                // recompute player regions and update troops due
                attacker.regions.push(event.payload.region);
                attacker.troopsDue = event.payload.attackerTroopsDue;

                if (defender) {
                    // if defender is not neutral
                    defender.regions = defender.regions.filter(region => region !== event.payload.region);
                    defender.troopsDue = event.payload.defenderTroopsDue;
                }

                // change game state to advance
                draft.state.state = GameStatus.Advance;
            })
        );
    }

    @Action(RegionBombardedEvent)
    regionBombarded(ctx: StateContext<GameStateModel>, event: RegionBombardedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {

                // TODO: update attack result?

                // update region owner to neutral and update troops
                draft.state.regions[event.payload.region].player = 0;
                draft.state.regions[event.payload.region].troops = event.payload.troops;

                const attacker = StoreGameState.getPlayer(draft, event.payload.conqueredBy);
                const defender = StoreGameState.getPlayer(draft, event.payload.conqueredFrom);

                // recompute player regions and update troops due
                attacker.troopsDue = event.payload.attackerTroopsDue;

                if (defender) {
                    // if defender is not neutral
                    defender.regions = defender.regions.filter(region => region !== event.payload.region);
                    defender.troopsDue = event.payload.defenderTroopsDue;
                }
            })
        );
    }

    @Action(RegionAdvancedEvent)
    regionAdvanced(ctx: StateContext<GameStateModel>, event: RegionAdvancedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update number of troops on regions
                this.updateRegionTroops(draft, event.payload.fromRegion, -event.payload.troops, true);
                this.updateRegionTroops(draft, event.payload.toRegion, event.payload.troops, true);

                // reset region selection
                draft.state.fromRegion = null;
                draft.state.toRegion = null;

                // next state is attack again
                draft.state.state = GameStatus.Attack;
            })
        );
    }

    @Action(AssaultEndedEvent)
    assaultEnded(ctx: StateContext<GameStateModel>) {
        // TODO: change map action to idle
    }


    @Action(ReinforcementStartedEvent)
    reinforcementStarted(ctx: StateContext<GameStateModel>, event: ReinforcementStartedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                draft.state.state = GameStatus.Reinforcement;
            })
        );
    }

    @Action(RegionReinforcedEvent)
    regionReinforced(ctx: StateContext<GameStateModel>, event: RegionReinforcedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update number of troops on regions
                if (event.payload.fromRegion) {
                    // on reserve call up regions can be reinforced and there are no fromRegion
                    this.updateRegionTroops(draft, event.payload.fromRegion, -event.payload.troops, true);
                }
                this.updateRegionTroops(draft, event.payload.toRegion, event.payload.troops, true);

                // reset region selection
                draft.state.fromRegion = null;
                draft.state.toRegion = null;
            })
        );
    }

    @Action(ReinforcementEndedEvent)
    reinforcementEnded(ctx: StateContext<GameStateModel>, event: ReinforcementEndedEvent) {
        // TODO: change map action to idle
    }


    @Action(PlayerDefeatedEvent)
    playerDefeated(ctx: StateContext<GameStateModel>, event: PlayerDefeatedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update player status
                StoreGameState.getPlayer(draft, event.payload.player).state = PlayerStatus.Defeated;
                // update last defeated player
                draft.state.lastDefeatedPlayer = event.payload.player;
            })
        );
    }

    @Action(PlayerGoneAWOLEvent)
    playerGoneAWOL(ctx: StateContext<GameStateModel>, event: PlayerGoneAWOLEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                const player = StoreGameState.getPlayer(draft, event.payload.player);
                // update player status
                player.state = PlayerStatus.AWOL;
                // update turns missed
                player.turnsMissed = event.payload.turnsMissed;
            })
        );
    }

    @Action(PlayerReturnedToBaseEvent)
    playerReturnedToBase(ctx: StateContext<GameStateModel>, event: PlayerReturnedToBaseEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update player status
                const player = StoreGameState.getPlayer(draft, event.payload.player);
                player.state = PlayerStatus.ReturningToBase;
            })
        );
    }

    @Action(PlayerBecameActiveEvent)
    playerBecameActive(ctx: StateContext<GameStateModel>, event: PlayerBecameActiveEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update player status
                const player = StoreGameState.getPlayer(draft, event.payload.player);
                player.state = PlayerStatus.Active;
            })
        );
    }


    @Action(DeferredTroopsDeploymentStartedEvent)
    deferredTroopsDeploymentStarted(ctx: StateContext<GameStateModel>, event: DeferredTroopsDeploymentStartedEvent) {
        // TODO: change map action to deployment if it's players turn
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // update troops due
                const player = StoreGameState.getPlayer(draft, event.payload.player);
                player.deferredTroops = event.payload.troops;

                // update game status
                draft.state.state = GameStatus.DeferredTroopsDeployment;
            })
        );
    }

    @Action(DeferredTroopsDeploymentEndedEvent)
    deferredTroopsDeploymentEnded(ctx: StateContext<GameStateModel>, event: DeferredTroopsDeploymentEndedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                const player = StoreGameState.getPlayer(draft, event.payload.player);
                player.deferredTroops = 0;
            })
        );
    }


    @Action(ChatUpdatedEvent)
    chatUpdated(ctx: StateContext<GameStateModel>, event: ChatUpdatedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                draft.messages.push(...event.messages);
            })
        );
    }


    @Action(ReserveAwardedEvent)
    reserveAwarded(ctx: StateContext<GameStateModel>, event: ReserveAwardedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                const player = StoreGameState.getPlayer(draft, event.payload.player);
                if (event.payload.reserve) {
                    player.reserves.push(event.payload.reserve);
                }
                player.reserveCount++;
            })
        );
    }

    @Action(ReserveCallUpStartedEvent)
    reserveCallUpStarted(ctx: StateContext<GameStateModel>, event: ReserveCallUpStartedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                draft.state.state = GameStatus.ReserveCallUp;
                draft.state.callUpMandatory = event.payload.mandatory;
            })
        );
    }

    @Action(ReserveCallUpEndedEvent)
    reserveCallUpEnded(ctx: StateContext<GameStateModel>, event: ReserveCallUpEndedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                // TODO
            })
        );
    }

    @Action(ReserveSetCalledUpEvent)
    reserveSetCalledUp(ctx: StateContext<GameStateModel>, event: ReserveSetCalledUpEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                const player = StoreGameState.getPlayer(draft, event.payload.player);
                if (event.payload.remaining.reserves) {
                    player.reserves = event.payload.remaining.reserves;
                }
                player.reserveCount = event.payload.remaining.reserveCount;
                player.troopsDue = event.payload.troopsDue;
                draft.state.troopsOnCallUp = event.payload.troopsOnCallUp;
            })
        );
    }

    @Action(RegionAutoDeployedEvent)
    regionAutoDeployed(ctx: StateContext<GameStateModel>, event: RegionAutoDeployedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                const region = draft.state.regions[event.payload.region];
                const player = StoreGameState.getPlayer(draft, event.payload.player);

                // update troops in region
                region.troops = event.payload.troopsAfterDeployment;

                let oldOwner = null;

                if (event.payload.tookOwnership || event.payload.regionBecameNeutral) {
                    // the old owner lost this region, save the old owner
                    oldOwner = StoreGameState.getPlayer(draft, region.player);
                }

                if (event.payload.tookOwnership) {
                    // ownership has changed, update region owner and recompute player regions
                    region.player = event.payload.player;
                    player.regions.push(event.payload.region);
                }
                if (event.payload.regionBecameNeutral) {
                    // region became neutral
                    region.player = 0;
                }

                if (oldOwner) {
                    // someone lost this region, update player regions
                    oldOwner.regions = oldOwner.regions.filter(_region => _region !== event.payload.region);
                }

                // update player troops due
                player.troopsDue = event.payload.troopsDue;
            })
        );
    }

    @Action(ReinforceableRegionsUpdatedEvent)
    reinforceableRegionsUpdated(ctx: StateContext<GameStateModel>, event: ReinforceableRegionsUpdatedEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                draft.state.reinforceableRegions = {...draft.state.reinforceableRegions, ...event.reinforcements};
            })
        );
    }

    @Action(UpdateClockEvent)
    clockUpdated(ctx: StateContext<GameStateModel>, event: UpdateClockEvent) {
        ctx.setState(
            produce(ctx.getState(), (draft: GameStateModel) => {
                draft.serverTime = event.timestamp;
            })
        );
    }

    /**
     * Memoized Selectors
     */

    @Selector()
    static status(state: GameStateModel) {
        return state.state ? state.state.state : null;
    }

    @Selector()
    static round(state: GameStateModel) {
        return state.state && state.state.round ? state.state.round : null;
    }

    @Selector()
    static mapKey(state: GameStateModel) {
        console.log('state.options line 922 game.state');
        console.log(state.options);
        return state.options ? state.options.map.key : null;
    }

    @Selector()
    static players(state: GameStateModel) {
        return state.state && state.state.players ? state.state.players : null;
    }

    @Selector()
    static currentPlayerId(state: GameStateModel) {
        return state.state.round.turn.player;
    }

    @Selector()
    static playerId(state: GameStateModel) {
        return state.player;
    }

    @Selector()
    static regions(state: GameStateModel) {
        return state.state && state.state.regions ? state.state.regions : null;
    }

    @Selector()
    static playersColors(state: GameStateModel) {
        return state.preferences ? state.preferences.playerColors : null;
    }

    @Selector()
    static gameOptions(state: GameStateModel) {
        return state.options;
    }

    @Selector()
    static attacks(state: GameStateModel) {
        return state.attacks;
    }

    @Selector()
    static record(state: GameStateModel) {
        return state.record;
    }

    @Selector()
    static troopsOnCallUp(state: GameStateModel) {
        return state.state && state.state.troopsOnCallUp ? state.state.troopsOnCallUp : null;
    }

    @Selector()
    static fromRegion(state: GameStateModel) {
        return state.state && state.state.fromRegion ? state.state.fromRegion : null;
    }

    @Selector()
    static toRegion(state: GameStateModel) {
        return state.state && state.state.toRegion ? state.state.toRegion : null;
    }

    @Selector()
    static isCallUpMandatory(state: GameStateModel) {
        return state.state && state.state.callUpMandatory ? state.state.callUpMandatory : false;
    }

    @Selector()
    static reinforceableRegions(state: GameStateModel) {
        return state.state && state.state.reinforceableRegions ? state.state.reinforceableRegions : {};
    }

    @Selector()
    static lastReadMessageTime(state: GameStateModel) {
        return state.lastReadMessageTime;
    }

    @Selector()
    static serverTime(state: GameStateModel) {
        return state.serverTime;
    }

    @Selector()
    static target(state: GameStateModel) {
        const player = StoreGameState.getPlayer(state, state.player);
        return player.target ? player.target : null;
    }

    @Selector()
    static lastDefeatedPlayer(state: GameStateModel) {
        if (!state.state.lastDefeatedPlayer) {
            return null;
        }
        return StoreGameState.getPlayer(state, state.state.lastDefeatedPlayer);
    }

    @Selector()
    static winnerPlayers(state: GameStateModel) {
        if (!state.state.winner || !state.state.winner.players) {
            return null;
        }
        return state.state.winner.players;
    }

    @Selector()
    static turnsTaken(state: GameStateModel) {
        return state.state.turnsTaken;
    }

    @Selector()
    static timeTaken(state: GameStateModel) {
        return state.state.timeTaken;
    }

    @Selector()
    static pointsAwarded(state: GameStateModel) {
        if (!state.state.winner) {
            return  null;
        }
        return state.state.pointsWon;
    }

    @Selector()
    static teams(state: GameStateModel) {
        if (!state.state.teams) {
            return [];
        }
        return state.state.teams;
    }

    @Selector()
    static cancelledMessage(state: GameStateModel) {
        if (!(state.state && state.state.reason)) {
            return null;
        }

        if (state.state.reason.message) {
            return state.state.reason.message;
        }

        switch (state.state.reason.reason) {
            case 'all_players_gone_awol':
                return 'The game was cancelled because all players went awol';
            default:
                return 'The game was cancelled';
        }
    }

    /**
     * Auxiliary functions
     */

    // TODO: those functions should belong to the GameStateModel class...

    static getPlayer(state: GameStateModel, playerId: number) {
        if (playerId === 0) {
            // neutral
            return null;
        }

        return state.state.players[state.state.players.findIndex(gameplayer => gameplayer.id === playerId)];
    };

    static getTeam(state: GameStateModel, teamId: number) {
        return state.state.teams[state.state.teams.findIndex(team => team.id === teamId)];
    };

    private updateRegionTroops(draft: GameStateModel, region: string, troops: number, increment: boolean) {
        draft.state.regions[region].troops = increment ? draft.state.regions[region].troops + troops : troops;
    }

    private computePlayerRegions(players: GamePlayer[], regions: any) {

        const playerRegions = Object.keys(regions).reduce((acc, regionId) => {
            const region = regions[regionId];
            if (!acc[region.player]) {
                acc[region.player] = [];
            }
            acc[region.player].push(regionId);
            return acc;
        }, {});

        // create a copy of array because we may not be able to modify the original one
        return players.map(player => {
            return {
                ...player,
                regions: playerRegions[player.id] ? playerRegions[player.id] : []
            };
        });
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { create } from 'rxjs-spy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    private onDestroy$ = new Subject<boolean>();

    gameId: number;

    constructor(private route: ActivatedRoute, private translate: TranslateService) {
        // const spy = create();
        // spy.log('containersCanvasObjects');
        // spy.log('titlesCanvasObjects');
        // spy.log('playerId');
        // spy.log('troopsOnCallUp');
        translate.setDefaultLang('en');
        translate.use('en');
    }

    ngOnInit(): void {
        this.route.queryParams
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe(params => {
                this.gameId = +params.game;
            })
        ;
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}

export interface GameStateModel {
    lastAction: number;
    lastActionTime: number;
    options: GameOptions;
    state: GameState;
    locked?: boolean;
    preferences: GamePreferences;
    messages: ChatMessage[];
    record: any[];
    player: number;
    attacks: AttackInfo[];
    lastReadMessageTime: number;
    serverTime: number;
}

export interface GamePreferences {
    playerColors: { [playerId: string]: string; };
    lastReadChat?: number;
}

export interface GameOptions {
    map: GameOptionsMap;
    force: string;
    objective: string;
    players: number;
    privacy: string;
    reinforcement: string;
    reinforcementAllowance: string;
    reserves: string;
    teams: string;
    turnLength: number;
    turnOrder: string;
}

export interface GameOptionsMap {
    key: string;
    version: number;
}

export interface GameState {
    players: GamePlayer[];
    teams: GameTeam[];
    regions: { [region: string]: GameRegion };
    state: GameStatus;
    troopsOnCallUp: number;
    round: GameRound;
    fromRegion?: string;
    toRegion?: string;
    callUpMandatory?: boolean;
    reinforceableRegions?: any;
    timeTaken?: number;
    turnsTaken?: number;
    pointsWon?: any;
    winner?: any;
    lastDefeatedPlayer?: number;
    reason?: CancelledReason;
}

export interface GameRegion {
    player: number;
    troops?: number;
}

export interface GamePlayer {
    id: number;
    isFirstGame: boolean;
    level: string;
    name: string;
    rank: string;
    score: number;
    deferredTroops?: number;
    regions?: string[];
    reserves?: Reserve[];
    reserveCount?: number;
    state?: PlayerStatus;
    troopsDue?: number;
    turnsMissed?: number;
    defeatedBy?: number; // defeated
    target?: number;
    team?: number;
}

export interface GameTeam {
    id: number;
    availableSlots?: number;
    players: number[];
}

export interface GameRound {
    number: number;
    otherPlayers: number[];
    turn: GameTurn;
}

export interface GameTurn {
    endsIn: number;
    number: number;
    player: number;
}

export interface ChatMessage {
    timestamp: number;
    scope: string;
    from: number;
    text: string;
}

export interface Reserve {
    region: string;
    color: ReserveColor;
}

export interface AttackInfo {
    attackDice: number[];
    defendDice: number[];
    attackResult: string;
    defendResult: string;
}

export interface CancelledReason {
    reason: string;
    message?: string;
}

export enum ReserveColor {
    Red = 'Red',
    Tan = 'Tan',
    White = 'White'
}

export enum PlayerStatus {
    Active          = 'active',
    AWOL            = 'awol',
    ReturningToBase = 'returning_to_base',
    Defeated        = 'defeated'
}

export enum GameStatus {
    WaitingForPlayers        = 'WaitingForPlayers',
    WaitingToStartTurn       = 'WaitingToStartTurn',
    Attack                   = 'Attack',
    Advance                  = 'Advance',
    DeferredTroopsDeployment = 'DeferredTroopsDeployment',
    Deployment               = 'Deployment',
    Reinforcement            = 'Reinforcement',
    ReserveCallUp            = 'ReserveCallUp',
    Paused                   = 'Paused',
    Ended                    = 'Ended',
    Cancelled                = 'Cancelled'
}

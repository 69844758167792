import { Injectable } from '@angular/core';
import { WebsocketService } from '../../../../core/services/websocket.service';

@Injectable({
    providedIn: 'root'
})
export class GameService {

    constructor(private socketService: WebsocketService) { }

    join() {
        this.socketService.send({
            cmd: 'join'
        });
    }

    joinTeam(team: number) {
        this.socketService.send({
            cmd: 'join',
            team
        });
    }

    leave() {
        this.socketService.send({
            cmd: 'leave'
        });
    }

    beginTurn() {
        this.socketService.send({
            cmd: 'begin_turn'
        });
    }

    deployTroops(region: string, count: number) {
        this.socketService.send({
            cmd: 'deploy',
            region,
            count
        });
    }

    skipDeployment() {
        this.socketService.send({
            cmd: 'skip_deployment'
        });
    }

    attack(fromRegion: string, toRegion: string) {
        this.socketService.send({
            cmd: 'attack',
            fromRegion,
            toRegion
        });
    }

    blitz(fromRegion: string, toRegion: string) {
        this.socketService.send({
            cmd: 'blitz',
            fromRegion,
            toRegion
        });
    }

    advanceTroops(troops: number) {
        this.socketService.send({
            cmd: 'advance',
            troops
        });
    }

    endAssault() {
        this.socketService.send({
            cmd: 'end_assault'
        });
    }

    reinforceRegion(fromRegion: string, toRegion: string, troops: number) {
        this.socketService.send({
            cmd: 'reinforce',
            fromRegion,
            toRegion,
            troops
        });
    }

    endReinforcement() {
        this.socketService.send({
            cmd: 'end_reinforcement'
        });
    }

    returnToBase() {
        this.socketService.send({
            cmd: 'return_to_base'
        });
    }

    // TODO: reserves type
    callUpReserves(reserves: any) {
        this.socketService.send({
            cmd: 'call_up_reserves',
            reserves
        });
    }

    skipReserveCallUp() {
        this.socketService.send({
            cmd: 'skip_reserve_call_up'
        });
    }

    /* others */

    getCurrentGame() {
        this.socketService.send({
            cmd: 'get_current_game'
        });
    }

    getReinforceableRegions(region?: string) {

        let params = {
            cmd: 'get_reinforceable_regions'
        };

        if (region) {
            params = Object.assign({}, params, { region });
        }

        this.socketService.send(params);
    }

    getGamePrefereces() {
        this.socketService.send({
            cmd: 'get_game_preferences'
        });
    }

    setPlayerColors(playerColors: object) {
        this.socketService.send({
            cmd: 'set_game_preferences',
            playerColors
        });
    }

    setPlayerLastReadChat(timestamp: number) {
        this.socketService.send({
            cmd: 'set_game_preferences',
            lastReadChat: timestamp
        });
    }

    getGameRecord() {
        this.socketService.send({
            cmd: 'get_game_record'
        });
    }

    getChatMessages() {
        this.socketService.send({
            cmd: 'get_posts'
        });
    }

    sendChatMessage(message: string, scope: string) {
        this.socketService.send({
            cmd: 'post',
            scope,
            message
        });
    }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GamePlayer } from '@game/store/models/game.model';

@Component({
    selector: 'app-player-list',
    templateUrl: './player-list.component.html',
    styleUrls: ['./player-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerListComponent implements OnChanges {

    maxColumns: number;

    @Input() players: GamePlayer[];
    @Input() currentPlayer: number;
    @Input() containers: any;
    @Input() targetPlayer: number;

    @Output() playerColorUpdated = new EventEmitter<any>();

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.players && changes.players.currentValue) {
            const totalLines = Math.ceil(changes.players.currentValue.length / 8);
            this.maxColumns = Math.ceil(changes.players.currentValue.length / totalLines);
        }
    }

    updatePlayerColor(playerId: number, colorId: string) {
        this.playerColorUpdated.emit({
            player: playerId,
            color: colorId
        });
    }
}

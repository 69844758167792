import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GameService } from '@gamelist/services/api/game.service';
import { Subject } from 'rxjs';
import { share, startWith, switchMap, tap } from 'rxjs/operators';
import { ModalService } from '@core/services/modal.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-game-search',
    templateUrl: './game-search.component.html',
    styleUrls: ['./game-search.component.scss']
})
export class GameSearchComponent implements OnInit {

    games$;
    filters$;
    selectedFilters = {
        status: ['slots_available', 'in_progress', 'archived'],
        turnLength: [86400, 300, 180],
        minSlots: null,
        maxSlots: null,
        minRank: null,
        maxRank: null,
        maps: [],
        players: [],
        objectives: [],
        teams: [],
        reserves: [],
        reinforcementAllowance: [],
        reinforcement: [],
        force: [],
        privacy: [],
        gamePlayers: [],
        isPlayer: false
    };
    selectedFilters$ = new Subject();

    @ViewChild('gameArea')
    gameArea: ElementRef;

    constructor(private gameService: GameService, private modalService: ModalService, private route: ActivatedRoute) { }

    ngOnInit() {
        const gameType = this.route.snapshot.queryParams.type;
        if (gameType === 'realtime') {
            this.selectedFilters.turnLength = [300, 180];
        } else if (gameType === 'casual') {
            this.selectedFilters.turnLength = [86400];
        }

        const onlyAvailable = this.route.snapshot.queryParams.available_slots === '1';
        if (onlyAvailable) {
            this.selectedFilters.status = ['slots_available'];
        } else {
            this.selectedFilters.status = ['slots_available', 'in_progress', 'archived'];
        }

        const showJoinedGames = this.route.snapshot.queryParams.showJoinedGames === 'true';
        this.selectedFilters.isPlayer = showJoinedGames;

        const maps = this.route.snapshot.queryParams.maps;
        if (maps) {
            this.selectedFilters.maps = maps.split(',');
        }

        this.filters$ = this.gameService.getSearchParams().pipe(
            share()
        );

        this.games$ = this.selectedFilters$.pipe(
            startWith(this.selectedFilters),
            switchMap(filters => this.gameService.listGames(filters))
        );
    }

    updateFilter(key, value) {
        this.selectedFilters[key] = value;
        this.applyFilters(this.selectedFilters);
    }

    toggleFilter(key, value) {
        if (this.selectedFilters[key].includes(value)) {
            this.selectedFilters[key] = this.selectedFilters[key].filter(_value => _value !== value);
        } else {
            this.selectedFilters[key].push(value);
        }
        this.applyFilters(this.selectedFilters);
    }

    applyFilters(filters) {
        this.selectedFilters$.next(filters);
    }

    showAdvancedSearch() {
        this.gameArea.nativeElement.style.height = '1225px';
        this.modalService.open('advanced-search');
    }

    applyAdvancedFilters(filters) {
        this.selectedFilters = Object.assign({}, this.selectedFilters, filters);
        this.applyFilters(this.selectedFilters);
    }

    readjustHeight() {
        this.gameArea.nativeElement.style.height = 'auto';
    }
}

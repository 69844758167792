import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { retryBackoff } from 'backoff-rxjs';
import { tap } from 'rxjs/operators';

const INIT_INTERVAL_MS = 1000;
const MAX_INTERVAL_MS = 3000;
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private socket$: WebSocketSubject<any>;

  constructor() {}

  public connect(
    url: string,
    openObserver: Subject<any>,
    closeObserver: Subject<any>
  ): Observable<any> {
    console.log('connecting to', url);
  
    if (!this.socket$) {
      this.socket$ = webSocket({
        url,
        openObserver,
        closeObserver
      });
    }
  
    return this.socket$.asObservable().pipe(
      retryBackoff({
        initialInterval: INIT_INTERVAL_MS,
        maxInterval: MAX_INTERVAL_MS
      }),
      tap(
        (data) => console.log('Received data:', data), // Print successful data
        (error) => console.error('Error:', error) // Print errors
      )
    );
  }
  

  public send(message: any): void {
    this.socket$.next(message);
  }
}


import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { GameService } from '@gamelist/services/api/game.service';
import { GameOptions } from '@gamelist/models/game-options';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-new-game',
    templateUrl: './new-game.component.html',
    styleUrls: ['./new-game.component.scss']
})
export class NewGameComponent implements OnInit {

    private maps;
    private combinations;

    difficultyFilter = ['Normal', 'Intermediate', 'Advanced'];
    sizeFilter = ['Small', 'Medium', 'Large'];
    gameOptions: GameOptions;
    optionsValid: boolean;
    filteredMaps;
    mapOptions;
    availableOptions = null;

    @ViewChild('options') optionsRef: ElementRef;

    constructor(private gameService: GameService, private router: Router, private toastr: ToastrService, private route: ActivatedRoute) {
        gameService.getAvailableMaps().subscribe(result => {
            this.maps = result.maps.map(map => {
                const imageUrl = `${environment.mapsUrl}/${map.key}/${map.version}/${map.key}.${map.extension}`;
                return Object.assign({}, map, {imageUrl});
            });
            this.combinations = result.combinations;

            this.filterMaps();
        });

        this.gameOptions = new GameOptions();
        this.optionsValid = false;
        this.availableOptions = {
            objective: [],
            teams: []
        };
    }

    ngOnInit() {
        const gameType = this.route.snapshot.queryParams.type;
        if (gameType === 'realtime') {
            this.gameOptions.turnLength = 300;
        } else if (gameType === 'casual') {
            this.gameOptions.turnLength = 86400;
        }
    }

    public setMap(map) {
        this.mapOptions = map.options;
        this.setGameOption('map', map);
        // scroll to options
        // we use timeout because element has no height yet because we are using an ngIf
        setTimeout(() => {
            this.optionsRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }, 0);
    }

    public setGameOption(key: string, value) {
        this.gameOptions[key] = value;

        if (key === 'map' || key === 'players' || key === 'teams' || key === 'objective') {
            this.computeAvailableOptions(this.gameOptions, this.combinations, key);
        }

        this.optionsValid = this.gameOptions.isValid();
    }

    public toggleDifficultyFilter(value: string) {
        if (this.difficultyFilter.includes(value)) {
            this.difficultyFilter = this.difficultyFilter.filter(v => v !== value);
        } else {
            this.difficultyFilter.push(value);
        }
        this.filterMaps();
    }

    public toggleSizeFilter(value: string) {
        if (this.sizeFilter.includes(value)) {
            this.sizeFilter = this.sizeFilter.filter(v => v !== value);
        } else {
            this.sizeFilter.push(value);
        }
        this.filterMaps();
    }

    private filterMaps() {
        this.filteredMaps = this.maps.filter(map => this.difficultyFilter.includes(map.difficulty) && this.sizeFilter.includes(map.size));
    }

    private computeAvailableOptions(selectedOptions, combinations, changedOption) {

        if (changedOption === 'map' && !this.mapOptions.players.includes(selectedOptions.players)) {
            // map changed and no longer includes the selected players options, so disable players, objective and team selection
            selectedOptions.players = null;
            selectedOptions.objective = null;
            selectedOptions.teams = null;
            this.availableOptions.objective = [];
            this.availableOptions.teams = [];
        }
        else if (changedOption === 'players') {
            this.availableOptions.objective = this.computeAvailableObjectives(selectedOptions, combinations);

            // disable selected if invalid
            if (!this.availableOptions.objective.includes(selectedOptions.objective)) {
                selectedOptions.objective = null;
                selectedOptions.teams = null;
                this.availableOptions.teams = [];
            } else {
                this.availableOptions.teams = this.computeAvailableTeams(selectedOptions, combinations);
                if (!this.availableOptions.teams.includes(selectedOptions.teams)) {
                    selectedOptions.teams = null;
                }
            }
        }
        else if (changedOption === 'objective') {
            this.availableOptions.teams = this.computeAvailableTeams(selectedOptions, combinations);
            // disable selected if invalid
            if (!this.availableOptions.teams.includes(selectedOptions.teams)) {
                selectedOptions.teams = null;
            }
        }
    }

    private computeAvailableObjectives(selectedOptions, combinations) {
        return combinations
            .filter(combination => combination[0] === selectedOptions.players)
            .reduce((acc, combination) => {
                const option = combination[1];
                if (!acc.includes(option)) {
                    acc.push(option);
                }
                return acc;
            }, [])
        ;
    }

    private computeAvailableTeams(selectedOptions, combinations) {
        return combinations
            .filter(combination => combination[0] === selectedOptions.players && combination[1] === selectedOptions.objective)
            .reduce((acc, combination) => {
                const option = combination[2];
                if (!acc.includes(option)) {
                    acc.push(option);
                }
                return acc;
            }, [])
        ;
    }

    public createGame() {
        const requestOptions = Object.assign(
            {},
            this.gameOptions,
            { map: { key: this.gameOptions.map.key, version: this.gameOptions.map.version } }
        );
        this.gameService.createGame(requestOptions)
            .subscribe(
                res => {
                    console.log(requestOptions);
                    console.log(res);
                    // this.router.navigate(['/games/' + res.id]);
                    window.top.location.href = `${environment.websiteUrl}/beta`;
                },
                () => {
                    this.toastr.error('An error occurred creating the game');
                }
            )
        ;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ScoreboardService {

    constructor(private http: HttpClient) { }

    getCurrentPointsLeaderboard() {
        return this.http.get(`${environment.apiUrl}/scoreboard/points-leader`, { withCredentials: true });
    }

    getConsecutiveDefeatedOpponents(timeframe: string) {
        return this.http.get(`${environment.apiUrl}/scoreboard/consecutive-defeated-opponents`, { withCredentials: true, params: { timeframe } });
    }

    getMostDecoratedPlayers() {
        return this.http.get(`${environment.apiUrl}/scoreboard/most-decorated`, { withCredentials: true });
    }

    getMostOpponentsDefeated() {
        return this.http.get(`${environment.apiUrl}/scoreboard/most-opponents-defeated`, { withCredentials: true });
    }

    getNewUsers() {
        return this.http.get(`${environment.apiUrl}/scoreboard/new-users`, { withCredentials: true });
    }

    getTotalActiveUsers() {
        return this.http.get(`${environment.apiUrl}/scoreboard/total-active-users`, { withCredentials: true }).pipe(
            map((res: any) => res.count)
        );
    }
}

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PlayersService {

    constructor(private http: HttpClient) { }

    searchByUsername(username: string): any {
        return this.http.get(`${environment.apiUrl}/users`, { withCredentials: true, params: { username } });
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-team-selection',
    templateUrl: './team-selection.component.html',
    styleUrls: ['./team-selection.component.scss']
})
export class TeamSelectionComponent implements OnInit {

    @Input() teams;
    @Output() joinTeam = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

}

export class GameOptions {
    map: any;
    players: number;
    objective: string;
    teams: string;
    turnOrder: string;
    reserves: string;
    reinforcementAllowance: string;
    reinforcement: string;
    force: string;
    turnLength: number;
    privacy: string;

    constructor() {
        this.map = null;
        this.players = null;
        this.objective = null;
        this.teams = null;
        this.turnOrder = 'Sequential';
        this.reserves = null;
        this.reinforcementAllowance = null;
        this.reinforcement = null;
        this.force = null;
        this.turnLength = null;
        this.privacy = 'public';
    }

    isValid() {
        return this.map !== null &&
            this.players !== null &&
            this.objective !== null &&
            this.teams !== null &&
            this.turnOrder !== null &&
            this.reserves !== null &&
            this.reinforcementAllowance !== null &&
            this.reinforcement !== null &&
            this.force !== null &&
            this.turnLength !== null &&
            this.privacy !== null
        ;
    }
}

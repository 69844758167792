import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { GameService } from '@gamelist/services/api/game.service';
import { ModalService } from '@core/services/modal.service';

@Component({
    selector: 'app-game-list',
    templateUrl: './game-list.component.html',
    styleUrls: ['./game-list.component.scss']
})
export class GameListComponent implements OnInit {

    @Input() games;
    selectedGame = null;
    teamGameToJoin = null;

    constructor(private gameService: GameService, private modalService: ModalService) {
    }

    ngOnInit() {
        console.log(this.games);
    }

    public toggleDetails(gameId: number) {
        this.selectedGame = this.selectedGame === gameId ? null : gameId;
    }

    public viewGame(game) {
        window.top.location.href = `${environment.websiteUrl}/beta/${game.id}`;
    }

    // public joinGame(game) {
    //     console.log('joingame', game.id);
    //     this.gameService.joinGame(game.id)
    //         .subscribe((response) => {
    //             if (response.result.ok) {
    //                 console.log(response.list);
                    
    //                 this.games = this.games.map(_game => {
                        
    //                     console.log('game filter', game.id, _game.id);
    //                     console.log(_game);
    //                     return game.id === _game.id ? response.list : _game;
    //                 });
    //                 console.log('After join game list', this.games);
    //             }
    //         })
    //     ;
    // }
    public joinGame(game) {
        console.log('joining game', game.id);
        this.gameService.joinGame(game.id)
        .subscribe((response) => {
            if (response.result.ok) {
                this.games = this.games.map(_game => {
                    console.log('game filter', game.id, _game.id);
                    if (game.id === _game.id) {
                        console.log('response list', response.list);
                        // Update the joined game with the response data
                        return response.list;
                    } else {
                        console.log('game', _game);
                        return _game; // Keep other games unchanged
                    }
                });
                console.log('After joining game list', this.games);
            }
        });
    }
    
    public joinTeamGame(game, teamId) {
        this.gameService.joinGame(game.id, teamId)
        .subscribe((response) => {
            if (response.result.ok) {
                this.modalService.close('team-selection');
                this.games = this.games.map(_game => {
                    return game.id === _game.id ? response.list : _game;
                });
                // Send a message to the parent frame to change the height
                const heightChange = { type: 'resize' };
                window.parent.postMessage(heightChange, environment.websiteUrl);
            }
        });
    }

    public leaveGame(game) {
        this.gameService.leaveGame(game.id).subscribe((response) => {
            if (response.result.ok) {
                this.games = this.games.map(_game => {
                    return game.id === _game.id ? response.list : _game;
                });
            }
        });
    }

    public showTeamSelection(game) {
        this.teamGameToJoin = game;
        this.modalService.open('team-selection');
       
        // Send a message to the parent frame to change the height
        const heightChange = { type: 'resize', height: '600' };
        window.parent.postMessage(heightChange, environment.websiteUrl);
    }
    
}
